import { defineStore } from 'pinia';
import router from '../router';
import inicioIcon from "../assets/icons/inicio.png";
import servicosIcon from "../assets/icons/servicos.png";
import agendaIcon from "../assets/icons/agenda.png";
import atendimentoIcon from "../assets/icons/atendimentos.png";
import pacientesIcons from "../assets/icons/pacientes.png";
import parceriasIcons from "../assets/icons/parcerias.png";
import financeiroIcons from "../assets/icons/financeiro.png";
import marketingIcons from "../assets/icons/marketing.png";
import universityIcons from "../assets/icons/university.png";

export const useMenu = defineStore('menu', {
    state: () => {
        return {
          pathName: window.location.pathname,
          items: [
            {
              label: "Início",
              icon: inicioIcon,
              active: inicioIcon,
              to: "/",
            },
            {
              label: "Atendimentos",
              icon: atendimentoIcon,
              items: [
                {
                  label: "Hoje",
                  to: "/appointment/today",
                },
                {
                  label: "Futuros",
                  to: "/appointment/futures",
                },
                {
                  label: "Anteriores",
                  to: "/appointment/previous",
                },
              ],
            },
            {
              label: "Agenda",
              icon: agendaIcon,
              items: [
                {
                  label: "Diária",
                  to: "/schedule/diary",
                },
                {
                  label: "Semanal",
                  to: "/schedule/week",
                },
                {
                  label: "Mensal",
                  to: "/schedule/monthly",
                },
              ],
            },
            {
              label: "Meus Serviços",
              icon: servicosIcon,
              items: [
                {
                  label: "Serviços Oferecidos",
                  icon: "fas fa-medkit icon",
                  to: "/myservices/services",
                },
                {
                  label: "Locais",
                  to: "/myservices/locations",
                },
                {
                  label: "Horários",
                  to: "/myservices/schedules",
                },
              ],
            },
            {
              label: "Pacientes",
              icon: pacientesIcons,
              to: "/patients"
            },
            {
              label: "Parcerias",
              icon: parceriasIcons,
              to: "/partnerships"
            },
            {
              label: "Financeiro",
              icon: financeiroIcons,
              to: "/financial"
            },
            {
              label: "Marketing",
              icon: marketingIcons,
              to: "/marketing"
            },
            {
              label: "University",
              icon: universityIcons,
              // to: "/marketing"
            },
          ]
        }
    },
    getters: {
    },
    actions: {
      changeRoute(path) {
        router.push(path);
        this.pathName = path
      },
      logout() {
        this.pathName = "/"
        router.push("/account/login");
      }
    }
});