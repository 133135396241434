<template>
    <div class="card-experiences">
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
            <div class="content">
                    <Skeleton width="100%" height="60px"/> 
            </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-experiences {
    display: flex;
    flex-direction: column;
    gap: 8px;
        
    .content {
        // box-shadow: 0px 10.2857px 30.8571px rgb(61 71 102 / 8%), 0px 3.85714px 7.71429px rgb(61 71 102 / 6%);
        // box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
        border-radius: 8px;
        gap: 8px;
        overflow: hidden;
        position: relative;
    } 
}


</style>