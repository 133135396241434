<template>
    <div class="loader">
        <div class="profile">
            <div class="flex align-items-center gap-12">
                <Skeleton size="52px" />
                <Skeleton width="200px" height="20px" />
            </div>
        </div>
        <div class="password">
            <div class="flex align-items-center gap-12">
                <Skeleton width="100px" height="14px" />
                <Skeleton width="70px" height="14px" />
            </div>
            <Skeleton width="22px" height="16px" />
            
        </div>
    </div>
</template>

<script>
export default {

    setup(props) {


        return {}
    }
}
</script>

<style lang="scss" scoped>
.loader {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 21px;
    gap: 14px;
    min-height: 60px;
    

    background: white;
    border-radius: 8px;
    .profile{
        display: flex;
        align-items: center;
        gap: 12px;
        background: #FAFAFA;
        padding: 14px;
        border-radius: 12px;
        min-height: 80px;
        width: 100%;
    }
    .password{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        background: #FAFAFA;
        border-radius: 10px;
        padding: 4px 14px;
        border: 1px solid #FAFAFA;
        height: 40px;
        width: 100%;
    }
}
</style>