<template>
    <li class="mode">
        {{ name_mode }}
    </li>
</template>

<script>
import { onMounted, ref, computed } from "vue";

const all_modes = [
    {name: "Presencial", bg: "#A2C0D4"},
    {name: "Videochamada", bg: "#2D313D"},
    {name: "Homecare", bg: "#FF6A33"},
]

export default {
    props: ["name_mode"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const getBg = computed(() => {
            if(all_modes.find((obj) => obj.name === props.name_mode)){
                return { background: all_modes.find((obj) => obj.name === props.name_mode).bg }
            }
            return { background: '#495057' }
        })
        
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            getBg,
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.mode {
    display: flex;
    align-items: center !important;
    gap: 6px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;

    .tag{
        width: 8px;
        height: 16px;
        border-radius: 16px;
        background: #A2C0D4;
    }
}


</style>