<template>
    <svg class="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.35 6.5C15.05 5.6 14.15 5 13.25 5C11.975 5 11 5.975 11 7.25C11 8.225 11.6 9.05 12.5 9.35V10.625C12.5 12.5 11 14 9.125 14C7.25 14 5.75 12.5 5.75 10.625V10.175C7.925 9.8 9.5 7.925 9.5 5.75V1.25C9.5 0.8 9.2 0.5 8.75 0.5H7.25C6.8 0.5 6.5 0.8 6.5 1.25C6.5 1.7 6.8 2 7.25 2H8V5.75C8 7.4 6.65 8.75 5 8.75C3.35 8.75 2 7.4 2 5.75V2H2.75C3.2 2 3.5 1.7 3.5 1.25C3.5 0.8 3.2 0.5 2.75 0.5H1.25C0.8 0.5 0.5 0.8 0.5 1.25V5.75C0.5 7.925 2.075 9.8 4.25 10.175V10.625C4.25 13.325 6.425 15.5 9.125 15.5C11.825 15.5 14 13.325 14 10.625V9.35C15.2 8.975 15.8 7.7 15.35 6.5Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }



@media screen and (min-width: 600px) {
//For Tablets
    .container {
        width: 100;
    }
}

@media screen and (min-width: 768px) {
//For Laptops
    .container {
        width: 738px;
    }
}

@media screen and (min-width: 992px) {
//For Large Laptops
    .container {
        width: 962px;
    }
}

@media screen and (min-width: 1280px) {
//For Big TV's (HD Screens) 
    .container {
        width: 1250px;
    }
}


@media screen and (min-width: 1920px) {
//For Projectors or Higher Resolution Screens (Full HD)
    .container {
        width: 1890px;
    }
}
@media screen and (min-width: 3840px) {
//For 4K Displays (Ultra HD)
    .container {
        width: 3810px;
    }
}
</style>