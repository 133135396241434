<template>
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00005 6.83C1.18741 7.01625 1.44087 7.12079 1.70505 7.12079C1.96924 7.12079 2.22269 7.01625 2.41005 6.83L6.00005 3.29L9.54005 6.83C9.72741 7.01625 9.98087 7.12079 10.2451 7.12079C10.5092 7.12079 10.7627 7.01625 10.9501 6.83C11.0438 6.73703 11.1182 6.62643 11.1689 6.50457C11.2197 6.38271 11.2458 6.25201 11.2458 6.12C11.2458 5.98798 11.2197 5.85728 11.1689 5.73542C11.1182 5.61356 11.0438 5.50296 10.9501 5.41L6.71005 1.17C6.61709 1.07627 6.50649 1.00187 6.38463 0.951105C6.26277 0.900336 6.13206 0.874198 6.00005 0.874198C5.86804 0.874197 5.73733 0.900336 5.61548 0.951105C5.49362 1.00187 5.38301 1.07627 5.29005 1.17L1.00005 5.41C0.906323 5.50296 0.831929 5.61356 0.78116 5.73542C0.730392 5.85728 0.704252 5.98798 0.704252 6.12C0.704252 6.25201 0.730392 6.38271 0.78116 6.50457C0.831929 6.62643 0.906323 6.73703 1.00005 6.83Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>