<template>
    <li class="crm">
        {{ occupation }}
    </li>
</template>

<script>
import Profile from "@/assets/IconsComponent/Profile.vue"

export default {
    props: ["occupation"],
    components: {
        Profile,
    },
    setup(props, { emit }) {

        return {}
    },
};
</script>


<style lang="scss" scoped>
.crm {
    display: flex;
    align-items: center !important;
    gap: 6px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;

    .tag{
        width: 8px;
        height: 16px;
        border-radius: 16px;
        background: #A2C0D4;
    }
}
</style>