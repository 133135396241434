<template>
    <li class="local">
        {{ local }}
    </li>
</template>

<script>
import { onMounted, ref, computed } from "vue";

export default {
    props: ["local", "color"],
    setup(props, { emit }) {
        const getBg = computed(() => {
            if(props.color){
                return { background: props.color }
            }
            return { background: '#495057' }
        })

        return {
            getBg,
        }
    },
};
</script>


<style lang="scss" scoped>
.local {
    display: flex;
    align-items: center !important;
    gap: 6px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;

    .tag{
        width: 14px;
        height: 14px;
        border-radius: 14px;
        background: #7000FF;
    }
}
</style>