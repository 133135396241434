
import { computed } from "vue";
import ArrowDown from "@/assets/IconsComponent/ArrowDown.vue"
import ArrowUp from "@/assets/IconsComponent/ArrowUp.vue"
import Dropdown from "@/modules/schedule/diary/components/List/Dropdown.vue"
import { useRouter } from "vue-router"

const all_status = [
    { label: "Em espera", bg: "#6FCF97" },
    { label: "Em exame", bg: "#BB6BD9"},
    { label: "Em consultório", bg: "#828282"},
    { label: "Confirmado", bg: "#2F80ED"},
    { label: "Cancelado", bg: "#EB5757"},
    { label: "Não compareceu", bg: "#EB5757"},
    { label: "Agendado", bg: "#A2C0D4"},
    { label: "Atendido", bg: "#6FCF97"},
]

export default {
    props: ["status", "index", "indexDropdownOpen", "bg", "patient_id", "obj"],
    emits: ["openDialogAcess", "changeNumber"],
    components: { ArrowDown, Dropdown, ArrowUp },
    setup(props, { emit }) {
        const router = useRouter()
        const getObj = computed(() => {
            if(all_status.find((obj) => obj.label === props.status)){
                return all_status.find((obj) => obj.label === props.status)
            }
            return { name: "", label: "-", bg: "#565656" }
        })

        const toggle = (index: any) => {
            // window.scrollBy(0, 1000);
            if(props.indexDropdownOpen === null){
                emit("changeNumber", index)
            }else{
                if(index === props.indexDropdownOpen){
                    emit("changeNumber", null)
                }else{
                    emit("changeNumber", index)
                }
            }
        }

        const openDialogAcess = () => {
            emit("changeNumber", null)
            emit('openDialogAcess');
        }

        const closeDropdown = () => {
            emit("changeNumber", null);
        }

        const redirectHealth = () => {
            router.push("/health")
        }


        return {
            open,
            toggle,
            openDialogAcess,
            getObj,
            closeDropdown,
            redirectHealth,
        }
    }
}
