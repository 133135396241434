<template>
    <div class="bg-modal" v-if="visible">
        <Dialog class="modal" :style="{
            width: '462px',
            borderRadius: '8px',
            zIndex: '3000 !important',
        }" :baseZIndex="0" :closable="true" :autoZIndex="false" :visible="visible" @hide="close"
            @update:visible="onUpdate">
            <template #header>
                <h5 class="title-header">Erro ao atualizar o token</h5>
            </template>
            <div class="content">
                
                <p>{{ msg }}</p>
            </div>
        </Dialog>
    </div>

</template>

<script >
import { ref, onMounted, watch, computed } from "vue";


export default {
    props: ["visible", "msg"],
    emits: ["update:visible"],
    components: {},
    setup(props, ctx) {
        const close = () => {
            clear()
            ctx.emit("update:visible", false)
        }

        const onUpdate = (val) => (!val) && ctx.emit("update:visible", val);


        onMounted(async () => {

        })

        return {
            close,
            onUpdate
        };
    },
};
</script>

<style lang="scss" scoped>
.bg-modal {
    width: 120vw;
    height: 120vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: rgb(250, 250, 250, .05);
    mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
    -webkit-mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
    backdrop-filter: blur(6px);
}

.content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .message-error {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 31px;
            white-space: nowrap;
            color: #EB5757;
        }
    }

    .p-dialog-header {
        span {
            font-family: 'Roboto' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 18px !important;
            line-height: 21px !important;
            color: #828282 !important;
        }
    }

    .container-profile {
        display: flex;
        align-items: center;
        gap: 12px;
        background: #FAFAFA;
        padding: 14px;
        border-radius: 12px;
        min-height: 80px;

        .avatar {
            width: 52px;
            height: 52px;
            border-radius: 14px;
            margin: 0;
            padding: 0;
        }

        .infos {
            .name {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 31px;
            }

            .crm {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #BDBDBD;
            }
        }
    }

    .container-password {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        background: #FAFAFA;
        border-radius: 10px;
        padding: 4px 14px;
        border: 1px solid #FAFAFA;
        height: 40px;

        span {
            flex-shrink: 0;
        }

        .btn-visible {
            cursor: pointer;
            flex-shrink: 0;
        }
    }

    .border-error {
        border: 1px solid #EB5757;
    }
}

::v-deep(.p-inputtext) {
    background: #FAFAFA;
    border: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #828282;

    &::placeholder {
        color: rgb(130, 130, 130, .3);
    }

    &:hover {
        border: none;
    }
}

::v-deep(.p-dialog) {
    z-index: 2300 !important;
}

::v-deep(.help) {
    background: #FAFAFA !important;
    border-color: #FAFAFA;
    box-shadow: none;

    color: #BDBDBD;
    border-radius: 128.571px;

    &:hover {
        background: #FAFAFA !important;
        border-color: #FAFAFA;
        box-shadow: none;
        color: #BDBDBD;
        text-shadow: #BDBDBD 0 0 0.1rem;
    }
}

::v-deep(.submit) {
    background: #FAFAFA !important;
    border-color: #FAFAFA;
    box-shadow: none;

    color: #828282;
    border-radius: 128.571px;
    gap: 8px;
    min-width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #FAFAFA !important;
        border-color: #FAFAFA;
        box-shadow: none;
        color: #828282;
        text-shadow: #828282 0 0 0.1rem;
    }
}
</style>
