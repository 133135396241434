import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b81824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-list" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "label-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.day) + " - Total: " + _toDisplayString($props.length), 1)
    ])
  ]))
}