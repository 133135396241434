<template>
    <li class="service">
        {{ service_name }}
    </li>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";
import Stethoscopo from "@/assets/IconsComponent/Stethoscopo.vue"

export default {
    props: ["service_name"],
    components: {
        Stethoscopo,
    },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.service {
    display: flex;
    align-items: center !important;
    gap: 6px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;

    .tag{
        width: 8px;
        height: 16px;
        border-radius: 16px;
        background: #A2C0D4;
    }
}


</style>